.container{
    width: 100%;
    display: grid;
    grid-template-columns: 200px auto;
    gap: 20px;
    align-items: flex-start;
    align-content: flex-start;
    background: #fafafa;
    transition: 0.2s all linear;
    overflow-x: hidden;
}

.sidebar_container{
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100dvh;
    max-height: 100dvh;
    width: 200px;
    background: #ffffff;
    z-index: 99;
    padding: 0px 10px 0px 10px;
    overflow-y:auto;
    transition: 0.2s all linear;
}

.sidebar_content{
    position: relative;
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.sidebar_header{
    width: 100%;
    /* Make height same as nav .... */
    min-height: 80px;
    max-height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0;
}

.menu{
    width: 35px;
    height:35px;
    border-radius: 50%;
    background: #0f58ce;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 0px 5px #524fef;
    box-shadow: 0px 0px 0px 5px #333;
    margin-left: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

.menu_icon{
    color: #fff;
    font-size: 18px;
}

.new_chat_btn,.faq_btn,.recent_btn{
    width: 100%;
    background:#F0F4F9;
    padding: 13px 10px 13px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 14px;
    border-radius: 30px;
    border: none;
    outline: none;
    cursor: pointer;
}

.plus_icon,.faq_icon,.recent_icon{
    color: #000 !important;
    font-size: 20px !important;
    font-weight: 400 !important;
}

.text{
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
    color: #333333;
}

.line_breaker{
    width: 100%;
    height: 1px;
    background: #3333331c;
}

.chat_history{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    /* min-height: calc(100dvh - 80px - 240px);
    max-height: calc(100dvh - 80px - 240px); */
}

.chat_box{
    width: 100%;
    padding: 10px;
    /* background: #F0F4F9; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
}

.chat_box.active{
   background: #D3E3FD;
       background: #F0F4F9;
}

.chat_box_text{
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
    color: #333333;
    max-width: 120px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.sidebar_menu_container{
    width: 100%;
    position: sticky;
    bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    background: #fff;
}

.sidebar_menu_container.expand_false{
    position: absolute;
    bottom: 10px;
}

.main_container{
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* Main container gap */
    gap: 10px;
    position: relative;
    grid-area: 2/-2/1/-1;
    overflow-x: hidden;
}

.container.expand_false{
    display: grid;
    grid-template-columns: 60px auto;
    gap: 0;
    gap: 10px;
}

.sidebar_container.expand_false{
    width: 60px;
}

.sidebar_content.expand_false .new_chat_btn,.sidebar_content.expand_false .faq_btn,.sidebar_content.expand_false .recent_btn{
    width: 100%;
    background:#F0F4F9;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.sidebar_content.expand_false .text{
    display: none;
    opacity: 0;
}

.chat_history.expand_false{
    display: none;
    opacity: 0;
}

@media screen and (max-width:750px){
    .container,.container.expand_false{
        grid-template-columns: 0 auto;
        gap: 0;
    }
    .sidebar_container,.sidebar_container.expand_false{
        width: 0px;
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}