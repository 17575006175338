.nav{
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #ffffff;
    padding: 0px 30px 0px 20px;
    /* Making navbar and sidebar header height same as 80px */
    min-height: 80px;
    max-height: 80px;
    z-index: 99;
}

.nav_menu{
    width: 35px;
    height:35px;
    border-radius: 50%;
    /* background: #0f58ce; */
    /* background: #000; */
    background: transparent;
    display: none;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* box-shadow: 0px 0px 0px 5px #524fef; */
    /* box-shadow: 0px 0px 0px 5px #333; */
    margin-left: 5px;
    border: none;
    outline: none;
    user-select: none;
    cursor: pointer;
}

.nav_menu_icon{
    color: #fff;
    font-size: 18px;
}

.menu_bar{
    width: 43px;
    height: 43px;
    object-fit: contain;
}

.logo_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.logo{
    width: 200px;
    height: 50px;
    object-fit: contain;
    cursor: pointer;
}

.name_logo{
    width: 160px;
    height: 44px;
    object-fit: contain;
    cursor: pointer;
    display: none;
}

.brand_name{
    color: #5f6368;
    font-family: 'Ubuntu',sans-serif;
    font-size: 24px;
    cursor: pointer;
}

.profile_info{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    position: relative;
}

.profile_avatar{
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.arrow_down{
    color: #5f6368 !important;
    font-size: 16px;
    font-weight: 800 !important;
}

.dropdown_menu{
    display: none;
    position: absolute;
    bottom: -100px;
    right: 0px;
    width: 150px;
    background: #fff;
    box-shadow: 0 0px 0px 2px #fafafa,0 10px 20px rgba(0,0,0,0.2);
}

.dropdown_menu.active{
    display: block;
    animation: fadeEffect 0.5s linear;
}

.menu_list{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.list_item_btn,.list_item_btn_logout{
    width: 100%;
    padding: 12px 10px 12px 10px;
    text-align: left;
    background: transparent;
    border: none;
    outline: none;
    font-size: 18px;
    color: #333;
    font-family: 'Ubuntu',sans-serif;
    box-shadow: 0 0 0 1px #fafafa;
}

.list_item_btn_logout{
    background: rgb(224, 45, 45);
    color: #fff;
}

@media screen and (max-width:750px){
    .nav_menu{
        display: flex;
    }
    .nav{
        padding: 0px 4px 0px 4px;
    }

    .logo{
       display: none;
    }
    
    .name_logo{
        display: block;
    }

}

@keyframes fadeEffect{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
